<template>
  <section class="why-choose-section">
    <h2 class="section-title">
      <span class="title-grey">为什么</span>
      <span class="title-gradient">选择易企创</span>
    </h2>
    <p class="section-subtitle">
      生产管理信息化、决策分析数字化、人机交互智能化，数智一体赋能您的企业运营。
    </p>
    <div class="choose-cards">
      <div v-for="(item, index) in chooseItems" :key="index">
        <div class="choose-card">
          <div class="card-header">
            <h3 class="card-title">{{ item.title }}</h3>
            <img :src="item.icon" alt="Icon" class="card-icon" />
          </div>
          <p class="card-description">{{ item.description }}</p>
          <el-link type="primary" class="card-link">了解详情→</el-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      chooseItems: [
        {
          title: "业务聚焦、一体化管理",
          description:
              "聚售企业价值链核心环节，以简单易用为目标，打造集“研、产、购、销、存”和OA管理一体化的企业生产经营管理系统，满足企业的鑫画化需求。",
          icon: require("@/assets/value_icon.png"),
        },
        {
          title: "数据为王、智能化决策",
          description:
              "独特的数据分析平台，强调“数据为王”，通过智能分析助力企业决策。管理层可实时获取关键数据，提升决策效率，实现数据驱动的业务增长",
          icon: require("@/assets/value_icon.png"),
        },
        {
          title: "智能助手、全方位赋能",
          description:
              "基于大语言模型和AIGC等人工智能技术，融合企业、行业与产业链等多源信息，创新交互模式，全方位为管理赋能，提升决策效果。",
          icon: require("@/assets/value_icon.png"),
        },
        {
          title: "数智一体、网络化系统",
          description:
              "通过生产管理信息化、决策分析数字化、人机交互智能化的有机结合，确保了信息流、决策流和执行流的高效运转，使企业管理者与吴不各司其职，提升整体效率与协同能力。",
          icon: require("@/assets/value_icon.png"),
        },
        {
          title: "灵活配置、低代码运维",
          description:
              "平台采用低代码架构，用户可轻松定制应用，快速交付。灵活配置功能确保满足客户的个性化需求，提升适应性和可扩展性。",
          icon: require("@/assets/value_icon.png"),
        },
        {
          title: "稳定安全、多终端适配",
          description:
              "支持桌面和移动端多端适配，确保用户在不同设备上获得一致体验。采用先进安全技术和稳定架构，保障数据安全和系统流畅性。",
          icon: require("@/assets/value_icon.png"),
        },
      ],
    };
  },
};
</script>

<style scoped>
.why-choose-section {
  padding: 60px 0;
  background: rgba(247, 249, 250, 1);
  text-align: center;
}

.section-title {
  font-size: 48px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 69.5px;
  margin-bottom: 10px;
}

.title-grey {
  color: rgba(166, 166, 166, 0.68);
}

.title-gradient {
  background: linear-gradient(
    90deg,
    rgba(22, 80, 139, 1) 0%,
    rgba(35, 133, 198, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.section-subtitle {
  font-size: 24px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 34.75px;
  color: rgba(50, 62, 77, 1);
  text-align: center;
  vertical-align: top;
  margin-bottom: 40px;
}

.choose-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.choose-card {
  width: 490px;
  height: 360px;
  opacity: 1;
  border-radius: 30px;
  background: rgba(255, 255, 255, 1);
  padding: 35px;
  margin-bottom: 18px;
  margin-right: 18px;
  text-align: left;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
}

.card-header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.card-title {
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 28px;
  background: linear-gradient(
    90deg,
    rgba(22, 80, 139, 1) 0%,
    rgba(35, 133, 198, 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card-icon {
  width: 88px;
  height: 88px;
}

.card-description {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 28.96px;
  color: rgba(50, 62, 77, 1);
  flex: 1 1 auto;
}

.card-link {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 28.96px;
  color: rgba(35, 132, 197, 1);
}
</style>
